var videoScreen = document.querySelector('video');
var playBtn = document.querySelector('.play-button');
var blend = document.querySelector('.video-blend');
var videoWrap = document.querySelector('.video-wrapper');
var videoPlayer = {
    
    _initialize: function () {
                
        if ($('video').length === 0) {
            return;
        }
        
        this.videoPlay();
        
    },
    
    videoPlay: function () {
        
        playBtn.onclick = function () {
            videoWrap.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
            videoScreen.play();
            blend.classList.add("hidden");
            videoScreen.classList.add("height-auto");
        };
        
        videoScreen.onclick = function () {
            videoScreen.pause();
            blend.classList.remove("hidden");
            videoScreen.classList.remove("height-auto");
        };
        videoScreen.addEventListener('ended', myHandler, false);
        
        function myHandler(e) {
            blend.classList.remove("hidden");
            videoScreen.classList.remove("height-auto");
        }
    }
    
    
};

$(document).ready(function () {
    
    videoPlayer._initialize();
    
});