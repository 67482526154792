var scrollTo = {

    _initialize: function () {
        this.scroll();
    },

    scroll: function () {

        var buttonDownloadTools = $('.menu-under-jumbotron-empathy .menu-purple-element .about-cours .buttons');
        var anhorToDownloadElement = $(".choos-download-tools #tools-element");

        var buttonHowToUse = $('.choos-download-tools .tools-element .how-to-use1 .download-pdf-wrapper-btn .button-violet-price');
        var mapaEmpathii = $('#mapa');




        function scrollTo(startPoint, endPoint) {


            $(startPoint).click(function () {

                $([document.documentElement, document.body]).animate({

                    scrollTop: $(endPoint).offset().top - 136

                }, 500);

            });
        }

        scrollTo(buttonDownloadTools, anhorToDownloadElement);
        scrollTo(buttonHowToUse, mapaEmpathii);


        $(window).on('load', function () {

            var win = $(this);
            if (win.width() < 654) {
                $('#purple-menu').removeClass('sticked');

            }
            else {
                $('#purple-menu').addClass('sticked');
            }

        }
        );


        document.querySelectorAll('a[href^="#"]').forEach(function (anchor) {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                var scrollToElements = document.querySelector(this.getAttribute('href'));
                var scrolltoElementOffset = $(scrollToElements).offset().top;
                var stickeyMenuValue = 80;
                var purpleMenu = $("#purple-menu");

                var scrollTopPosition = purpleMenu.hasClass("sticked") ? parseInt(scrolltoElementOffset) - stickeyMenuValue :
                    parseInt(scrolltoElementOffset);



                $('html, body').animate({
                    scrollTop: scrollTopPosition
                }, 500);
            });
        });


    },
};
$(document).ready(function () {

    scrollTo._initialize();

});