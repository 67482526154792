var aHrefAttribute = {

    _initialize: function () {

        this.setAttribute();
    },

    setAttribute: function () {
        
        var bolgDiv = document.querySelectorAll('.blog-opis-content');
 
        bolgDiv.forEach(function(item){

            var bolgDivAHref = item.querySelectorAll('a');

            bolgDivAHref.forEach(function(item){

                item.setAttribute("target", "_blank");

            });

        });

    },

};

$(document).ready(function () {

    aHrefAttribute._initialize();

});