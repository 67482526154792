var empathyMap = {

    _init: function () {

        this.empathyMapRegisterEvents();
    },

    empathyMapRegisterEvents: function () {

        var allSelects = $('.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element');

        var formatValue = $('#format-select');

        var formatValueClass = $('.format-select');

        var chooseFormat = $('#choose-format');

        var languageValue = $('#language-select');

        var languageValueClass = $('.language-select');

        var chooseLanguage = $('#choose-language');

        var allLinks = $('#button-tools .button a');

        var downloadButton = $('#button-tools .button');

        var correctDownloadLink = $('#download-link');

        var buttonForShadow = $('.select .button-list-wrapper .main-button');

        var boxes = $('.select .button-list-wrapper .main-button');


        $(document).ready(function () {

            var formatSelected;

            var languageSelected;

            allSelects.find('.select .button-list-wrapper .main-button').click(function (event) {

                var element = jQuery(event.target).next('ul');

                var element2 = jQuery(event.target);

                if (element.hasClass('visible')) {

                    element.closest('.select').css("background-color", "transparent");

                    element.removeClass('visible');

                } else {
                    element.closest('.select').css("background-color", "white");
                    element.addClass('visible');
                }

                // if (element2.hasClass('white-button-shadow')) {
                //     element2.removeClass('white-button-shadow');
                // } else {
                //     element2.addClass("white-button-shadow");
                // }
            });

            function redy(choosedElement, whatChange) {
                $(choosedElement).click(function () {

                    $(whatChange).removeClass('visible');
                    $(whatChange).closest('.select').css("background-color", "transparent");
                    $(this).addClass("white-button-shadow");
                    // if ($(choosedElement).hasClass('white-button-shadow')) {
                    //     $(whatChange).removeClass('white-button-shadow');
                    // } else {
                    //     $(whatChange).addClass("white-button-shadow");
                    // }
                });
            }
            redy(chooseFormat, languageValueClass);
            redy(chooseLanguage, formatValueClass);


            formatValue.find('li').click(function (event) {

                var valueFormat = event.target.innerHTML;

                getFormatValue(event.target);

                if (formatValue.hasClass('visible')) {
                    formatValue.closest('.select').css("background-color", "transparent");
                    formatValue.removeClass('visible');
                }

                if (buttonForShadow.hasClass('white-button-shadow')) {
                    buttonForShadow.removeClass('white-button-shadow');
                }
                if (buttonForShadow.hasClass('white-button-shadow')) {
                    buttonForShadow.removeClass('white-button-shadow');
                }

                document.getElementById('choose-format').innerHTML = valueFormat;


            });

            languageValue.find('li').click(function (event) {
                var valueLanguage = event.target.innerHTML;

                getLanguageValue(event.target);

                if (languageValue.hasClass('visible')) {
                    languageValue.closest('.select').css("background-color", "transparent");
                    languageValue.removeClass('visible');
                }

                if (buttonForShadow.hasClass('white-button-shadow')) {
                    buttonForShadow.removeClass('white-button-shadow');
                }
                if (buttonForShadow.hasClass('white-button-shadow')) {
                    buttonForShadow.removeClass('white-button-shadow');
                }

                document.getElementById('choose-language').innerHTML = valueLanguage;

            });

            function getFormatValue(element) {

                formatSelected = jQuery(element).text();

                consitionsChecking();

            }

            function getLanguageValue(element) {

                languageSelected = jQuery(element).text();

                consitionsChecking();

            }

            function consitionsChecking() {

                correctDownloadLink.removeAttr("href");

                jQuery(downloadButton).addClass('disabled');

                $.each(allLinks, function (key, value) {

                    if (value.id === languageSelected + formatSelected) {

                        downloadButton.removeClass("disabled");

                        correctDownloadLink.attr("href", value.href);
                    }
                });

            }

        });

    },

};

empathyMap._init();