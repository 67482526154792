var sticky = {
    
    _init: function() {
        this.handleSticky();    
    },
    
    handleSticky: function() {
        
        var NavY = $('.sticked').offset().top;
        
        var stickyNav = function(){
            var ScrollY = $(window).scrollTop();
            
            if (ScrollY > NavY) { 
                $('.sticked').addClass('sticky').css('height', 'auto');
            } else {
                $('.sticked').removeClass('sticky'); 
            }
        };
        
        stickyNav();
        
        $(window).scroll(function() {
            stickyNav();
        });
        
    }
};

$(document).ready(function () {
    sticky._init();
});