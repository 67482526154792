var popupContact = {

    exitButton: '.popup-wrapper .popup-cotent .exit-cross',

    _init: function () {

        this.registerEvents();

    },

    registerEvents: function () {

        var self = this;

        $(this.exitButton).click(function () {
            self.hidePopup(); 
        });

        $(document).mouseup(function (e) {

            var container = $(".popup-wrapper .popup-cotent");

            if (!container.is(e.target) && container.has(e.target).length === 0) {
                self.hidePopup();
            }

        });

        $(document).keyup(function (e) {

            if (e.keyCode === 27) {
                self.hidePopup();
            }

        });

    },

    hidePopup: function () {

        $('.popup-wrapper').fadeOut('slow');

    }
};

$(document).ready(function () {
    popupContact._init();
});