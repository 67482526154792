var socialMediaCarusel = {

    _initialize: function () {
        this.instagramCarusel();
    },

    instagramCarusel: function () {

        if ($('.owl-carousel').length === 0) {
            return;
        }

        $('.owl-carousel').owlCarousel({
            margin: 10,
            loop: true,
            autoWidth: true,
            items: 7
        });

    },
};
$(document).ready(function () {

    socialMediaCarusel._initialize();

});