// var toolsFilter = {

//     _initialize: function () {

//         this.collapseFilter();

//     },

//     collapseFilter: function () {

//         var filterList = document.querySelector(".filter-list");

//         // if (filterList.length === 0) {
//         //     return;
//         // }
//         $(".dropdown .dt").click(function (e) {
//             filterList.classList.toggle("filter-visible");
//             e.preventDefault();
//         });

//         $(".dropdown .dd ul li a").click(function () {
//             var text = $(this).html();
//             $(".dropdown .dt").html(text);
//             filterList.classList.remove("filter-visible");
//         });

//         $(document).bind('click', function (e) {
//             var $clicked = $(e.target);
//             if (!$clicked.parents().hasClass("dropdown"))
//                 filterList.classList.remove("filter-visible");
//         });
//     }
// };

// $(document).ready(function () {

//     toolsFilter._initialize();

// });