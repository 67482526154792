var planKursu = {

    _initialize: function () {
        this.displayPlan();
        this.displayFaq();
        this.transformSVGToInline();
    },

    displayPlan: function () {

        $('ul.plan-ul li:first ul.article-content').addClass('display-inline');

        $('ul.plan-ul li:first header.article-title span.title-arrow').addClass('close-tab');

        $('.article-title').on("click", function () {
            var trg = this.parentNode.childNodes[3];
            trg.classList.toggle("display-inline");

            var arrow = this.childNodes[3];
            $(arrow).toggleClass("close-tab");

            if ($(arrow).hasClass('close-tab')) {

                this.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                });
            }
        });
    },


    displayFaq: function () {
        $('.faq-quest-title').on("click", function () {
            this.classList.toggle("faq-border");
            var trg = this.parentNode.childNodes[3];
            trg.classList.toggle("faq-inline");
            var arrow = this.childNodes[3];
            arrow.classList.toggle("close-tab");

            $(this).find("h3").toggleClass("faq-pleo-fiolet-text");
        });
    },


    transformSVGToInline: function() {
        $('img.svg').each(function() {
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class'); 
            var imgURL = $img.attr('src');

            $.get(imgURL, function(data) {
                var $svg = $(data).find('svg');

                if (typeof imgID !== 'undefined')
                $svg = $svg.attr('id', imgID);

                if (typeof imgClass !== 'undefined')
                $svg = $svg.attr('class', imgClass + ' replaced-svg');

                $svg = $svg.removeAttr('xmlns:a');
                $img.replaceWith($svg);

            }, 'xml');

        });

    },
};

$(document).ready(function () {

    planKursu._initialize();

});

var x = $('span.title-arrow');
AOS.init({
    duration: 800,
});