var showCross = {

    _initialize: function () {
        this.cross();
    },

    cross: function () {

        var checkBoxContactUs = $('.contact-form-third .input-radio');
        var checkBoxWeContactYou = $('.contact-form-third #cross-element');

        var crossOne = $('.contact-form-third .input-radio .check-contact-us .crossOne');
        var crossTwo = $('.contact-form-third #cross-element .check-we-contact-you .crossTwo');


        $(document).ready(function () {
            $('input[type="checkbox"]').click(function () {
                if ($(this).prop("checked") == true) {
                    $(checkBoxContactUs).click(function (e) {
                        $(crossOne).addClass("visible");

                    });
                    $(checkBoxWeContactYou).click(function (e) {
                        $(crossTwo).addClass("visible");

                    });
                } else if ($(this).prop("checked") == false) {
                    $(checkBoxContactUs).click(function (e) {
                        $(crossOne).removeClass("visible");

                    });
                    $(checkBoxWeContactYou).click(function (e) {
                        $(crossTwo).removeClass("visible");

                    });
                }
            });
        });
    },
};
$(document).ready(function () {

    showCross._initialize();

});