var hamburgerMenu = {

    _initialize: function () {
        this.collapseMenu();
    },

    collapseMenu: function () {
        var menuButton = $(' nav .collaps-menu-icon');
        var menuContent = $('.hamburger-menu .collaps-menu');
        var html = $(document.documentElement);

        $(menuButton).click(function () {
            $(menuContent).toggleClass('visible');

            if (menuContent.hasClass('visible')) {
                $(html).css('overflow-y', 'hidden');
            } else {
                $(html).css('overflow-y', 'auto');

            }
        });

    },
};

$(document).ready(function () {

    hamburgerMenu._initialize();

});