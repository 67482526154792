var calender = {

    _initialize: function () {
        this.formCalender();
    },

    formCalender: function () {

        var date = new Date();
        date.setDate(date.getDate());


        $('.datepicker').datepicker({
            defaultDate: new Date(),
            startDate: date,
            language: 'pol',
            setDate: new Date(),
            autoclose: true,
            todayHighlight: true,
            startView: 0,
        });
    },
};

$(document).ready(function () {

    calender._initialize();

});